// Import the data
const placesData = require('../data/poi.json');

// Helper function to add delay to simulate network latency
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Helper function to parse location string
const parseLocation = (locationStr) => {
  try {
    // Remove the curly braces and split by comma
    const locationData = locationStr
      .replace(/{|}/g, '')
      .split(', ')
      .reduce((acc, item) => {
        const [key, value] = item.split('=');
        acc[key] = value;
        return acc;
      }, {});
    
    return locationData;
  } catch (error) {
    console.error('Error parsing location:', error);
    return null;
  }
};

// Helper function to transform MongoDB-style document
const transformDocument = (doc) => {
  if (!doc) return null;
  
  return {
    ...doc,
    id: doc._id.$oid, // Add a simple id field
    location: parseLocation(doc.location),
    coordinates: doc.coordinates || [
      parseFloat(parseLocation(doc.location)?.lng),
      parseFloat(parseLocation(doc.location)?.lat)
    ]
  };
};

// Mock API functions
export const mockFetchPlaces = async ({ limit = 10, offset = 0 } = {}) => {
  await delay(300); // Simulate network delay
  
  // Filter enabled places and transform data
  const enabledPlaces = placesData
    .filter(place => place.enabled)
    .map(place => transformDocument(place));
  
  // Apply pagination
  const paginatedPlaces = enabledPlaces.slice(offset, offset + limit);

console.log('Paginated Places:', paginatedPlaces);

  return {
    places: paginatedPlaces,
    total: enabledPlaces.length,
    limit,
    offset
  };
};

export const mockFetchPlaceById = async (id) => {
  await delay(200);
  
  const place = placesData.find(p => p._id.$oid === id);
  
  if (!place) {
    throw new Error('Place not found');
  }
  
  return transformDocument(place);
}; 