import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPlaces } from '../store/placesSlice';
import './HomePage.css';
import { DEFAULT_PLACE_IMAGE } from '../constants/images';
import SEO from '../components/SEO';
import Footer from '../components/Footer';

const HomePage = ({ displayCount: propDisplayCount = 10 }) => {
    const displayCount = propDisplayCount || process.env.REACT_APP_DISPLAY_COUNT || 10;
    const dispatch = useDispatch();
    const placesState = useSelector((state) => state.places);
    const places = placesState?.items || [];
    const loading = placesState?.loading?.places;
    const error = placesState?.error;

    useEffect(() => {
        dispatch(getPlaces({ page: 1, limit: displayCount }));
    }, [dispatch, displayCount]);

    console.log('Places State:', placesState);
    console.log('Places Array:', places);

    if (loading) return <div className="home-container">Loading...</div>;
    if (error) return <div className="home-container">Error: {error}</div>;
    if (!places?.places || !Array.isArray(places.places)) return <div className="home-container">No places available</div>;

    return (
        <>
            <SEO 
                title="Find Your Perfect Yoga Destination"
                description="Discover amazing yoga places worldwide. WHEREYOGI helps you find the perfect destination for your next yoga adventure with detailed listings and reviews."
            />
            <div className="home-container">
                <h1>Welcome to WHEREYOGI</h1>
                <div className="content">
                    <p>Discover amazing places and plan your next yoga adventure!</p>
                    
                    <div className="places-grid">
                        {places.places.map(place => (
                            <Link 
                                to={`/place/${place.id}`} 
                                className="place-card" 
                                key={place.id}
                            >
                                <div className="place-image">
                                    <img 
                                        src={place.imageUrl || DEFAULT_PLACE_IMAGE}
                                        alt={place.title} 
                                    />
                                </div>
                                <div className="place-info">
                                    <h3>{place.title}</h3>
                                    <p>{place.location.city}, {place.location.country}</p>
                                </div>
                            </Link>
                        ))}
                    </div>

                    <Link to="/places" className="cta-button">
                        View All Places
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HomePage; 