import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPlaceById } from '../store/placesSlice';
import './PlacePage.css';
import { DEFAULT_PLACE_IMAGE } from '../constants/images';
import SEO from '../components/SEO';
import Footer from '../components/Footer';

const PlacePage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { 
        selectedPlace: place, 
        loading: { selectedPlace: loading }, 
        error 
    } = useSelector((state) => state.places);

    useEffect(() => {
        dispatch(getPlaceById(id));
    }, [dispatch, id]);

    if (loading) return <div className="place-container">Loading...</div>;
    if (error) return <div className="place-container">Error: {error}</div>;
    if (!place) return <div className="place-container">Place not found</div>;
    
    return (
        <>
            <SEO 
                title={place.title}
                description={`Visit ${place.title} in ${place.location.city}, ${place.location.country}. Find detailed information about this yoga destination including location, contact details, and more.`}
                image={place.imageUrl || DEFAULT_PLACE_IMAGE}
                type="article"
            />
            <div className="place-container">
                <h1>{place.title}</h1>
                <div className="place-content">
                    <div className="place-image">
                        <img 
                            src={place.imageUrl || DEFAULT_PLACE_IMAGE}
                            alt={place.title} 
                        />
                    </div>
                    <div className="place-description">
                        <h2>Location Details</h2>
                        <p>{place.location.formattedAddress}</p>
                        
                        <h3>Contact Information</h3>
                        <ul>
                            <li>Address: {place.location.address}</li>
                            <li>City: {place.location.city}</li>
                            <li>State: {place.location.state}</li>
                            <li>Country: {place.location.country}</li>
                        </ul>

                        <h3>Coordinates</h3>
                        {place.coordinates ? (
                            <>
                                <p>Latitude: {place.coordinates[1]}</p>
                                <p>Longitude: {place.coordinates[0]}</p>
                            </>
                        ) : (
                            <p>No coordinates available</p>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PlacePage; 