import { mockFetchPlaces, mockFetchPlaceById } from './mockApi';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api';

const isDevelopment = process.env.REACT_APP_STAGE==='development';

export const fetchPlaces = async ({ limit, offset } = {}) => {
    // Use mock API in development
    if (isDevelopment) {
        return mockFetchPlaces({ limit, offset });
    }

    try {
        const queryParams = new URLSearchParams();
        if (limit) queryParams.append('limit', limit);
        if (offset) queryParams.append('offset', offset);
        
        const response = await fetch(
            `${API_BASE_URL}/places${queryParams.toString() ? '?' + queryParams.toString() : ''}`
        );
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error fetching places:', error);
        throw error;
    }
};

export const fetchPlaceById = async (id) => {
    // Use mock API in development
    if (isDevelopment) {
        return mockFetchPlaceById(id);
    }

    try {
        const response = await fetch(`${API_BASE_URL}/places/${id}`);
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error fetching place:', error);
        throw error;
    }
}; 