import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ 
    title, 
    description, 
    image, 
    url = window.location.href,
    type = 'website'
}) => {
    const siteName = 'WHEREYOGI';
    const fullTitle = `${title} | ${siteName}`;
    
    return (
        <Helmet>
            {/* Basic metadata */}
            <title>{fullTitle}</title>
            <meta name="description" content={description} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={fullTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            {image && <meta property="og:image" content={image} />}

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={fullTitle} />
            <meta name="twitter:description" content={description} />
            {image && <meta name="twitter:image" content={image} />}
        </Helmet>
    );
};

export default SEO; 