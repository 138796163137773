import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPlaces, setPage } from '../store/placesSlice';
import './PlacesPage.css';
import { DEFAULT_PLACE_IMAGE } from '../constants/images';
import SEO from '../components/SEO';
import Footer from '../components/Footer';

const PlacesPage = () => {
    const dispatch = useDispatch();
    const { 
        items: places, 
        loading: { places: loading }, 
        error,
        pagination: { currentPage, totalPages, limit }
    } = useSelector((state) => state.places);

    useEffect(() => {
        dispatch(getPlaces({ page: currentPage, limit }));
    }, [dispatch, currentPage, limit]);

    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage));
    };

    if (loading) return <div className="places-container">Loading...</div>;
    if (error) return <div className="places-container">Error: {error}</div>;
    if (!places?.places || !Array.isArray(places.places)) return <div className="places-container">No places available</div>;

    return (
        <>
            <SEO 
                title="Explore Yoga Destinations"
                description="Browse our comprehensive directory of yoga destinations worldwide. Find detailed information about yoga studios, retreats, and wellness centers across the globe."
            />
            <div className="places-container">
                <h1>All Places</h1>
                <div className="content">
                    <div className="places-grid">
                        {places.places.map(place => (
                            <Link 
                                to={`/place/${place.id}`} 
                                className="place-card" 
                                key={place.id}
                            >
                                <div className="place-image">
                                    <img 
                                        src={place.imageUrl || DEFAULT_PLACE_IMAGE}
                                        alt={place.title} 
                                    />
                                </div>
                                <div className="place-info">
                                    <h3>{place.title}</h3>
                                    <p>{place.location.city}, {place.location.country}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    
                    <div className="pagination">
                        <button 
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button 
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PlacesPage; 