import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
    return (
        <div className="about-page">
            <h1>About WHEREYOGI</h1>
            <p>WHEREYOGI is your go-to platform for discovering amazing yoga destinations worldwide. Our mission is to help you find the perfect place for your next yoga adventure.</p>
        </div>
    );
};

export default AboutPage; 