import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPlaces, fetchPlaceById } from '../lib/api';

const initialState = {
  items: [],
  selectedPlace: null,
  loading: {
    places: false,
    selectedPlace: false
  },
  error: null,
  pagination: {
    total: 0,
    currentPage: 1,
    limit: 10,
    totalPages: 0
  }
};

export const getPlaces = createAsyncThunk(
  'places/getPlaces',
  async ({ page = 1, limit = 10 } = {}) => {
    const offset = (page - 1) * limit;
    const response = await fetchPlaces({ limit, offset });
    return {
      items: response.items || response,
      total: response.total || response.length,
      page,
      limit
    };
  }
);

export const getPlaceById = createAsyncThunk(
  'places/getPlaceById',
  async (id) => {
    const response = await fetchPlaceById(id);
    return response;
  }
);

const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
    setLimit: (state, action) => {
      state.pagination.limit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlaces.pending, (state) => {
        state.loading.places = true;
        state.error = null;
      })
      .addCase(getPlaces.fulfilled, (state, action) => {
        state.loading.places = false;
        state.items = action.payload.items;
        state.pagination = {
          total: action.payload.total,
          currentPage: action.payload.page,
          limit: action.payload.limit,
          totalPages: Math.ceil(action.payload.total / action.payload.limit)
        };
      })
      .addCase(getPlaces.rejected, (state, action) => {
        state.loading.places = false;
        state.error = action.error.message;
      })
      .addCase(getPlaceById.pending, (state) => {
        state.loading.selectedPlace = true;
        state.error = null;
      })
      .addCase(getPlaceById.fulfilled, (state, action) => {
        state.loading.selectedPlace = false;
        state.selectedPlace = action.payload;
      })
      .addCase(getPlaceById.rejected, (state, action) => {
        state.loading.selectedPlace = false;
        state.error = action.error.message;
      });
  },
});

export const { setPage, setLimit } = placesSlice.actions;
export default placesSlice.reducer; 